<template>
  <v-container>
    <BaseTitlePage :title="checkPageTitle" class="mb-0" />

    <BaseSearchPage class="mt-0">
      <template v-slot:form>
        <v-form
          @submit.prevent="init"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-row dense>
            <v-col cols="12" :sm="tabVisible ? 6 : 5">
              <BaseCustomers
                id="customerId"
                name="customerId"
                label="Estipulante"
                hide-details
                required
                :rules="[required]"
                :disabled="tabVisible"
                v-model="info.customerId"
              />
            </v-col>
            <v-col cols="12" :sm="tabVisible ? 6 : 5">
              <BaseProductType
                id="productType"
                name="productType"
                label="Tipo de produto"
                hide-details
                required
                :rules="[required]"
                :disabled="tabVisible"
                v-model="info.productType"
              />
            </v-col>

            <v-col v-if="!tabVisible" cols="12" sm="2">
              <BaseButton
                id="btn-init"
                name="btn-init"
                type="submit"
                height="40"
                color="primary"
                title="Iniciar"
                :disabled="!valid"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <v-row v-if="tabVisible">
      <v-col cols="12">
        <v-tabs
          show-arrows
          :vertical="checkMobile"
          class="b-tab-vertical"
          v-model="tab"
        >
          <template v-for="(header, index) in headers">
            <v-tab
              class="b-tab-vertical--tab"
              :disabled="header.disabled"
              :href="`#${header.href}`"
              :key="index"
              v-if="header.visible"
            >
              <v-icon small v-if="checkMobile">{{ header.icon }}</v-icon>
              {{ header.title }}
            </v-tab>
          </template>

          <v-tabs-items v-model="tab" class="b-tab-vertical--items">
            <template v-for="(content, index) in headers">
              <v-tab-item
                v-if="content.visible"
                :value="content.href"
                :key="index"
              >
                <component
                  :is="content.component"
                  :editMode="editMode"
                  :productType="info.productType"
                  :customerId="info.customerId"
                  :hasSub="info.hasSub"
                  @back="back"
                  @updateInfo="searchInfo"
                  v-if="content.href === tab"
                />
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    PageContract: () => import('@/components/pages/contracts/page-contract'),
    PageCustomers: () => import('@/components/pages/contracts/page-customers'),
    PageCoverage: () => import('@/components/pages/contracts/page-coverage'),
    PageCapital: () => import('@/components/pages/contracts/page-capital'),
    PagePlans: () => import('@/components/pages/contracts/page-plans'),
    PageDocuments: () => import('@/components/pages/contracts/page-documents'),
    PageParticularities: () =>
      import('@/components/pages/contracts/page-particularities'),
    PageLeaveBeneficiary: () =>
      import('@/components/pages/contracts/page-leave-beneficiary'),
    PageAccessData: () =>
      import('@/components/pages/contracts/page-access-data')
  },

  data: () => ({
    tabVisible: false,
    valid: true,
    tab: null,
    info: {
      id: null,
      customerId: null,
      productType: null,
      hasSub: false
    }
  }),

  mounted() {
    if (this.editMode) {
      this.searchInfo();
    }
  },

  computed: {
    editMode() {
      return this.$route.params && this.$route.params.id ? true : false;
    },

    checkPageTitle() {
      const { id } = this.$route.params;
      return id ? 'Visualizar contrato' : 'Cadastrar contrato';
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    headers() {
      return this.$store.getters['contracts/getContractTabs'];
    }
  },

  methods: {
    async searchInfo() {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.getContractInfo(
          this.$route.params
        );

        if (status === 200) {
          this.info = data;
          this.$store.commit('contracts/createTabs', data);

          this.enableTabs(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    enableTabs(result) {
      if (result && result.id) {
        this.tabVisible = true;
      }
    },

    init() {
      if (!this.$refs.form.validate(true)) return;

      this.$store.commit('contracts/createTabs', this.info);

      setTimeout(() => {
        this.tabVisible = true;
      }, 200);
    },

    back() {
      this.$refs.form.reset();
      this.$router.push({ name: 'contratos' });
    }
  }
};
</script>
